import { throttle } from 'throttle-debounce';
import FocusTrapper from '../../utils/FocusTrapper';
import Toggler from '../../utils/Toggler';
import getCSSCustomProp from '../../utils/getCSSCustomProp';

export default class Header {
  constructor(root) {
    // Elements
    this.$root = root;
    this.$burger = this.$root.querySelectorAll('.js-header-burger');
    this.$navigation = this.$root.querySelector('.js-header-nav');
    this.$navigationInner = this.$root.querySelector('.js-header-nav-inner');
    this.$submenuRoots = this.$root.querySelectorAll('.js-header-submenu-root');

    // Throttled functions
    this.throttledWatchResize = throttle(250, this.watchResize.bind(this));

    // Variables
    this.nav2ColBP = getCSSCustomProp('--header-nav-2col-bp', undefined, 'int');
    this.navIsOn2Col = () => window.matchMedia(`(min-width: ${this.nav2ColBP}em)`).matches;

    // Focus trapper for navigation
    this.navFocusTrapper = new FocusTrapper(this.$navigationInner);

    // Togglers syndication
    this.togglers = {
      nav: null,
      submenus: null,
    };

    // Nav toggler
    this.togglers.nav = new Toggler([[this.$navigation, this.$burger]], {
      escClose: true,
      callbacks: {
        afterOpen: () => {
          this.navFocusTrapper.catch();

          document.body.classList.add('nav-is-open');
          document.body.classList.add('no-scroll');
        },
        afterClose: () => {
          this.navFocusTrapper.release();

          document.body.classList.remove('nav-is-open');
          document.body.classList.remove('no-scroll');

          // Ensure submenus or subrubrics are also closed
          if (this.togglers.submenus.currentId) {
            this.togglers.submenus.close(this.togglers.submenus.currentId);
          }
        },
      },
    });

    // Submenus toggler
    const submenuTogglerItems = [];

    for (let i = 0; i < this.$submenuRoots.length; i++) {
      submenuTogglerItems.push([
        this.$submenuRoots[i].querySelector('.js-header-submenu-menu'),
        this.$submenuRoots[i].querySelectorAll('.js-header-submenu-parent'),
      ]);
    }

    this.togglers.submenus = new Toggler(submenuTogglerItems, {
      oneAtATime: true,
      callbacks: {
        afterOpen: ($content) => {
          this.navFocusTrapper.update();
          document.body.classList.add('submenu-is-open');

          if (this.navIsOn2Col()) {
            this.$navigationInner.style.minHeight = `${$content.offsetHeight}px`;
          }
        },
        afterClose: () => {
          this.navFocusTrapper.update();

          document.body.classList.remove('submenu-is-open');

          this.$navigationInner.style.minHeight = '';
        },
      },
    });
  }

  mount() {
    this.togglers.nav.attach();
    this.togglers.submenus.attach();

    this.watchResize();

    window.addEventListener('resize', this.throttledWatchResize);
  }

  unmount() {
    this.togglers.nav.detach();
    this.togglers.submenus.detach();

    window.removeEventListener('resize', this.throttledWatchResize);
  }

  watchResize() {
    if (this.navIsOn2Col()) {
      if (this.togglers.submenus.currentId) {
        this.$navigationInner.style.minHeight = `${document.querySelector(`#${this.togglers.submenus.currentId}`).offsetHeight}px`;
      }
    } else {
      this.$navigationInner.style.minHeight = '';
    }
  }
}
