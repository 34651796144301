import arrayAreIdenticals from '../../../utils/arrayAreIdenticals';
import extend from '../../../utils/extend';
import getFragmentFromString from '../../../utils/getFragmentFromString';

export default class Field {
  constructor($element, options) {
    this.$element = $element;

    this.options = extend({
      errorTemplate: (text, id) => `
        <div class="c-form-note c-form-note--error c-form-field__feedback" id="${id}">
          <span class="c-icon c-form-note__icon" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
              />
            </svg>
          </span>
          <p class="c-form-note__text">${text}</p>
        </div>
      `,
    }, options);

    this.$control = this.$element.querySelector('.c-form-field__control');
    this.type = this.$element.getAttribute('data-type');
    this.isASet = ['input-set', 'option-set'].includes(this.type);
    this.$inputs = this.$element.querySelectorAll('.c-form-input, .c-form-option');
    this.$inputControls = this.$element.querySelectorAll('.c-form-input__control, .c-form-option__input');
    this.errorID = `${this.$element.getAttribute('data-prefix')}error`;
    this.$error = this.$element.querySelector('.c-form-note--error');
    this.$errorText = this.$error ? this.$error.querySelector('.c-form-note__text') : null;
    this.hasError = !!this.$error;
    this.valueInError = this.$error ? this.getValue() : '';

    this.handleChange = this.handleChange.bind(this);
  }

  mount() {
    if (this.isASet) {
      this.$inputControls.forEach(($inputControl) => {
        $inputControl.addEventListener('change', this.handleChange);
      });
    } else {
      this.$inputControls[0].addEventListener('change', this.handleChange);
    }
  }

  unmount() {
    if (this.isASet) {
      this.$inputControls.forEach(($inputControl) => {
        $inputControl.removeEventListener('change', this.handleChange);
      });
    } else {
      this.$inputControls[0].removeEventListener('change', this.handleChange);
    }
  }

  handleChange() {
    if (this.hasError && !arrayAreIdenticals(this.getValue(), this.valueInError)) {
      this.removeError();
    }
  }

  getValue() {
    const value = [];

    switch (this.type) {
      case 'input-set':
        this.$inputControls.forEach(($inputControl) => {
          value.push($inputControl.value);
        });
        break;
      case 'single-option':
        value.push(this.$inputControls[0].checked);
        break;
      case 'option-set':
        this.$inputControls.forEach(($inputControl) => {
          value.push($inputControl.checked);
        });
        break;
      default:
        value.push(this.$inputControls[0].value);
        break;
    }

    return value;
  }

  setError(text) {
    const firstError = !this.$error;

    if (firstError) {
      this.$error = getFragmentFromString(this.options.errorTemplate(text, this.errorID));
    } else {
      this.$errorText.innerText = text;
    }

    if (!this.hasError) {
      this.$element.insertBefore(this.$error, this.$control);
    }

    if (firstError) {
      this.$error = this.$element.querySelector('.c-form-note--error');
      this.$errorText = this.$error.querySelector('.c-form-note__text');
    }

    this.$inputs.forEach(($input) => {
      if (['single-option', 'option-set'].includes(this.type)) {
        $input.classList.add('c-form-option--error');
      } else {
        $input.classList.add('c-form-input--error');
      }
    });
    this.addAriaDescribedby([this.errorID]);
    this.hasError = true;
    this.valueInError = this.getValue();
  }

  removeError() {
    this.hasError = false;

    this.$error.remove();
    this.$inputs.forEach(($input) => {
      if (['single-option', 'option-set'].includes(this.type)) {
        $input.classList.remove('c-form-option--error');
      } else {
        $input.classList.remove('c-form-input--error');
      }
    });
    this.removeAriaDescribedby([this.errorID]);
    this.hasError = false;
  }

  setAriaDescribedby(value) {
    if (this.isASet) {
      if (value) {
        this.$element.setAttribute('aria-describedby', value);
      } else {
        this.$element.removeAttribute('aria-describedby');
      }
    } else if (value) {
      this.$inputControls[0].setAttribute('aria-describedby', value);
    } else {
      this.$inputControls[0].removeAttribute('aria-describedby');
    }
  }

  addAriaDescribedby(ids = []) {
    let existingIDs = [];

    if (this.isASet) {
      existingIDs = this.$element.getAttribute('aria-describedby').split(' ');
    } else {
      existingIDs = this.$inputControls[0].getAttribute('aria-describedby').split(' ');
    }

    this.setAriaDescribedby(existingIDs.concat(ids).join(' '));
  }

  removeAriaDescribedby(ids = []) {
    let existingIDs = [];

    if (this.isASet) {
      existingIDs = this.$element.getAttribute('aria-describedby').split(' ');
    } else {
      existingIDs = this.$inputControls[0].getAttribute('aria-describedby').split(' ');
    }

    ids.forEach((id) => {
      if (existingIDs.includes(id)) {
        existingIDs.splice(existingIDs.indexOf(id), 1);
      }
    });

    this.setAriaDescribedby(existingIDs.join(' '));
  }
}
