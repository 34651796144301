let $closedDetails;

// Doing things when opening print modal
window.addEventListener('beforeprint', () => {
  // Load [loading="lazy"] images
  const $lazyImages = document.querySelectorAll('img[loading="lazy"]');

  $lazyImages.forEach(($img) => {
    $img.removeAttribute('loading');
  });

  // Open details elements
  $closedDetails = document.querySelectorAll('details:not([open])');

  $closedDetails.forEach(($details) => {
    $details.open = true;
  });
});

// Doing things when closing the print modal
window.addEventListener('afterprint', () => {
  // Close details elements
  $closedDetails.forEach(($details) => {
    $details.open = false;
  });

  $closedDetails = null;
});
