import Field from '../components/Form/Field';

window.addEventListener('DOMContentLoaded', () => {
  const $fields = document.querySelectorAll('.js-form-field');

  if ($fields.length) {
    $fields.forEach(($field) => {
      const field = new Field($field);
      field.mount();
    });
  }
});
